<template>
  <div class="jk-bg-wrapper">
    <div class="jk-wrapper">

      <div class="jk-header _img">
        <div class="_back" @click="$router.go(-1)"><img src="../status/img/icon_back.png" /></div>
        <div class="_img"><img :src="goodsInfo.head_image" /></div>
      </div>

      <div class="jk-content"  >
        <div class="_img">
          <img :src="goodsInfo.content" />
<!--          博鳌国际医院-->
          <span v-if="goodsInfo.id==2" class="goods_id_2_xcap" @click="getResoueceById('6f2e644b-10fe-4b33-84dc-9310f774983d')" ></span>
          <span v-if="goodsInfo.id==2" class="goods_id_2_tjnr" @click="getResoueceById('6f2e644b-10fe-4b33-1234-9310f7743331')"></span>
<!--          华西高端体检-->
          <span v-if="goodsInfo.id==13" class="goods_id_13_a" @click="jumpShowPic(goodsId,'19','f6ec6b78-675a-4da2-ad2d-832b5b5fe483')"></span>
          <span v-if="goodsInfo.id==13" class="goods_id_13_b" @click="jumpShowPic(goodsId,'20','7b94e8e9-214a-4579-bbe3-62cb8557adf3')"></span>
          <span v-if="goodsInfo.id==13" class="goods_id_13_c" @click="jumpShowPic(goodsId,'21','6e36d1a4-c4e2-4f52-9dad-df7ef8edf1cf')"></span>
<!--五星酒店下午茶-->
          <span v-if="goodsInfo.id==27" class="goods_id_27" @click="getResoueceById( 'f3bcb3ed-de79-4811-ad26-fa1ed9ed1ca4')"></span>
<!--          五星酒店自助餐-->
          <span v-if="goodsInfo.id==28" class="goods_id_28" @click="getResoueceById( '64b01393-a01c-4621-b0de-68304e12d78e')"></span>

<!--          日式收纳-->
          <span v-if="goodsInfo.id==7" class="goods_id_2_tjnr" @click="getResoueceById('3bc36faa-ca64-42b2-899c-db93bed9978b')"></span>
          <!--            洗车服务-->
          <span v-if="goodsInfo.id==17" class="xcfw_1" @click="get_sub_goods_info(37);"></span>
          <span v-if="goodsInfo.id==17" class="xcfw_2" @click="get_sub_goods_info(38);"></span>
          <!--          双流机场-->
          <span v-if="goodsInfo.id==14" class="shuangliu_1" @click="get_sub_goods_info(31)" ></span>
          <span v-if="goodsInfo.id==14" class="shuangliu_2" @click="get_sub_goods_info(32)"></span>
          <span v-if="goodsInfo.id==14" class="shuangliu_3" @click="get_sub_goods_info(33)"></span>
          <!--          天府机场-->
          <span v-if="goodsInfo.id==15" class="tianfu_1" @click="get_sub_goods_info(34)" ></span>
          <span v-if="goodsInfo.id==15" class="tianfu_2" @click="get_sub_goods_info(35)"></span>
          <span v-if="goodsInfo.id==15" class="tianfu_3" @click="get_sub_goods_info(36)"></span>
          <!--          天府机场-->
          <span v-if="goodsInfo.id==15" class="tianfu_1" @click="get_sub_goods_info(34)" ></span>
          <span v-if="goodsInfo.id==15" class="tianfu_2" @click="get_sub_goods_info(35)"></span>
          <span v-if="goodsInfo.id==15" class="tianfu_3" @click="get_sub_goods_info(36)"></span>
          <!--            全家福-->
          <span v-if="goodsInfo.id==8" class="qjf_1" @click="get_sub_goods_info(8);"></span>
          <span v-if="goodsInfo.id==8" class="qjf_2" @click="get_sub_goods_info(41);"></span>
          <!--            羽毛球乒乓球-->
          <span v-if="goodsInfo.id==26" class="ymq_1" @click="get_sub_goods_info(48);"></span>
          <span v-if="goodsInfo.id==26" class="ppq_1" @click="get_sub_goods_info(49);"></span>

        </div>
      </div>
      <template >
        <div class="jk-btn" @click="onClickSignup" v-if="show_submit_btn">
          <img src="../status/img/btn_yy.png" />
        </div>
        <div class="jk-foot _img" >
          <img src="../status/img/me_line.png" />
        </div>
      </template>
    </div>


    <!-- 日式收纳 -->
    <div class="dialog-wrapper" v-if="showDayDialog">
      <div class="_content">
        <div class="_window _window_w">
<!--          <div class="_title _img"><img src="../status/img/dialog_title2.png" /></div>-->
          <div class="_img">
            <img :src="showImage"/>
<!--日式收纳-->
            <span v-if="goodsInfo.id==7" class="rssn_1" @click="get_sub_goods_info(22);"></span>
            <span v-if="goodsInfo.id==7" class="rssn_2" @click="get_sub_goods_info(23);"></span>
            <span v-if="goodsInfo.id==7" class="rssn_3" @click="get_sub_goods_info(24);"></span>
            <span v-if="goodsInfo.id==7" class="rssn_4" @click="get_sub_goods_info(25);"></span>
            <span v-if="goodsInfo.id==7" class="rssn_5" @click="get_sub_goods_info(26);"></span>
            <span v-if="goodsInfo.id==7" class="rssn_6" @click="get_sub_goods_info(27);"></span>
            <span v-if="goodsInfo.id==7" class="rssn_7" @click="get_sub_goods_info(28);"></span>
            <span v-if="goodsInfo.id==7" class="rssn_8" @click="get_sub_goods_info(29);"></span>
            <span v-if="goodsInfo.id==7" class="rssn_9" @click="get_sub_goods_info(30);"></span>
          </div>
        </div>
        <div class="_closed" @click="showDayDialog = false"><img src="../status/img/icon_closed.png" /></div>
      </div>
    </div>

    <!-- 确定预约 -->
    <div class="dialog-wrapper" v-if="showSureDialog">
      <div class="_content">
        <div class="_window">
          <div class="_title _img"><img src="../status/img/dialog_title1.png" /></div>
          <div class="_list">
            <div class="_xiangmu">
              <div class="_h1">预约项目</div>
              <div class="_h2">{{subGoodsInfo.title}}</div>
            </div>
            <div class="_dijian">
              <van-row>
                <van-col span="8">消耗积点</van-col>
                <van-col span="16"><span class="_hd">{{subGoodsInfo.price}}积点/{{subGoodsInfo.inventory_unit}}</span></van-col>
              </van-row>
            </div>
            <div class="_dijian" v-if="goodsInfo.goods_type==2 && goodsInfo.account_flag==1">
             <van-row>
               <van-col span="8">充值账号</van-col>
               <van-col span="16">
                 <input class="input_account" type="text" maxlength="20" placeholder="输入充值账号" v-model="account" >
               </van-col>
             </van-row>
            </div>
          </div>
          <div class="_btn"><img src="../status/img/dialog_btn.png" @click="onClickSureSignup" /></div>
        </div>
        <div class="_closed" @click="showSureDialog = false"><img src="../status/img/icon_closed.png" /></div>
      </div>
    </div>
    <!-- 预约成功 -->
    <div class="dialog-wrapper" v-if="showOkDialog">
      <div class="_content">
        <div class="_window">
          <div class="_title _img"><img src="../status/img/dialog_title_ok.png" /></div>
          <div class="_status"><img src="../status/img/btn_ok.png" /></div>
          <div class="_tips">请在【我的】-【我的预约】中查看兑换结果。</div>
          <div class="_list">
            <div class="_dijian">
              <van-row>
                <van-col span="8">消耗积点</van-col>
                <van-col span="16"><span class="_hd">{{subGoodsInfo.price}}积点</span></van-col>
              </van-row>
            </div>
            <div class="_dijian">
              <van-row>
                <van-col span="8">剩余积点</van-col>
                <van-col span="16"><span class="_hd">{{userInfo.integral}}积点</span></van-col>
              </van-row>
            </div>
          </div>
          <div class="_btn"><img src="../status/img/dialog_btn.png" @click="showOkDialog = false" /></div>
        </div>
        <div class="_closed" @click="showOkDialog = false"><img src="../status/img/icon_closed.png" /></div>
      </div>
    </div>
    <!-- 预约失败 -->
    <div class="dialog-wrapper" v-if="showFalseDialog">
      <div class="_content">
        <div class="_window">
          <div class="_title _img"><img src="../status/img/dialog_title_false.png" /></div>
          <div class="_status"><img src="../status/img/btn_false.png" /></div>
          <div class="_tips">{{error_msg}}</div>
          <div class="_list">
            <div class="_dijian">
              <van-row>
                <van-col span="8">所需积点</van-col>
                <van-col span="16"><span class="_hd">{{subGoodsInfo.price}}积点</span></van-col>
              </van-row>
            </div>
            <div class="_dijian">
              <van-row>
                <van-col span="8">剩余积点</van-col>
                <van-col span="16"><span class="_hd">{{userInfo.integral}}积点</span></van-col>
              </van-row>
            </div>
            <div class="_dijian" v-if="errorMessage!=''">
              <van-row>
                <van-col span="24"><span class="_hd">{{errorMessage}}</span></van-col>
              </van-row>
            </div>
          </div>
          <div class="_btn"><img src="../status/img/dialog_btn.png" @click="showFalseDialog = false" /></div>
        </div>
        <div class="_closed" @click="showFalseDialog = false"><img src="../status/img/icon_closed.png" /></div>
      </div>
    </div>
    <Footer :step="2" />
  </div>
</template>

<script>

import Cookies from "js-cookie";
import Footer from './components/footer'
import {Toast} from "vant";

export default {
  mixins: [],
  components: {Footer},
  data() {
    return {
      showDayDialog: false,
      showSureDialog: false,
      showOkDialog: false,
      showFalseDialog: false,
      goodsInfo:{},
      subGoodsInfo:null,
      errorMessage:"",
      orderInfo:{},
      goodsId:'',
      showImage:"",
      account:"",
      show_submit_btn:true,
      userInfo:{},
      error_msg:""
    };
  },

  created() {
    this.goodsId = this.$route.query.id
    if(this.goodsId==13 || this.goodsId==17|| this.goodsId==14 || this.goodsId==15|| this.goodsId==8|| this.goodsId==26 ){
      this.show_submit_btn=false;
    }
  },

  mounted() {
     this.getGoodsInfo();
     this.getMyInfo();
  },

  methods: {
    async getMyInfo() {
      // 获取分类列表
      let {data,code } = await this.$ajax.gateway("/api/api_get_my_info", {
        "METHOD": "get"
      });
      if(code!=200){
        Cookies.remove('admin_hdbank_test_token');
        this.$router.push("/login")
      }else{
        this.userInfo=data;
       if(this.goodsInfo.goods_type==2 && this.goodsInfo.account_flag==1){
         if(this.goodsId!=22){
           this.account=this.userInfo.phone;
         }
       }else{
         this.account = this.userInfo.phone;
       }
      }
    },
    async getGoodsInfo() {
      let {data} = await this.$ajax.gateway("/api/api_get_goods_detail", {
        "METHOD": "get",
        id:this.goodsId
      });
      this.goodsInfo = data;
      console.info(this.goodsInfo);
      if(this.goodsInfo.sub_goods.length==1){
        this.subGoodsInfo = this.goodsInfo.sub_goods[0];
      }
    },

   async getResoueceById(id){
      // 获取资源图片地址
      let {data,code} = await this.$ajax.gateway("/api/api_get_resource_by_id", {
        "METHOD": "get",
        "id": id
      });
      if(code==200){
        this.showImage = data.pic_url;
        this.showDayDialog = true;
      }else{
        alert("网络错误")
      }

    },

    // 行程安排
    onClickDayTips() {
      if(this.goodsInfo.id==2){
        this.showDayDialog = true;
      }

    },
    jumpShowPic(goods_id,sub_goods_id,pid){
      this.$router.push({path:'/showPic',query:{subGoods:sub_goods_id,picId:pid,goodsId:goods_id}})
    },
    // 判断预约
    onClickSignup() {
      if(this.goodsInfo.id==7 && !this.showSureDialog){
        this.getResoueceById('3bc36faa-ca64-42b2-899c-db93bed9978b')
        return false;
      }
      if(this.subGoodsInfo!=null){
        this.showSureDialog=true;
      }
    },
    async get_sub_goods_info(id){
      // 获取子商品详情
      let {data} = await this.$ajax.gateway("/api/api_get_sub_goods_detail_by_id", {
        "METHOD": "get",
        "id": id
      });
      this.subGoodsInfo = data;
      this.showSureDialog=true;
    },
    async submit_order(goods_id,sub_goods_id,bookings_date){
      if(this.goodsInfo.goods_type==2){
        if(this.goodsId==22 && this.account==""){
          Toast("输入QQ号进行充值")
          return false;
        }
        if(this.goodsId!=22 && this.account==""){
          Toast("输入手机号充值")
          return false;
        }
      }

      this.showDayDialog = false;
      if(this.userInfo.integral<this.goodsInfo.sub_goods[0].price){
        this.error_msg="积分不够" ;
        this.showFalseDialog=true;
        return false;
      }
      this.showSureDialog = false;
      // 用户预约
      let {data,code,message} = await this.$ajax.gateway("/api/api_buy_goods", {
        ERROR_BACK:true,
        "body": {
          "sub_goods_id": sub_goods_id,
          "bookings_date": bookings_date,
          "goods_id":goods_id,
          "account":this.account
        }
      });
      console.info(data,code,message);
      if(code==200){
        this.getMyInfo();
        this.orderInfo=data;
        this.showSureDialog=false;
        this.showOkDialog = true;
      }else{
        this.showFalseDialog=true;
        this.errorMessage=data.message;
      }
    },
    // 确认预约
    onClickSureSignup() {

      this.submit_order(this.goodsInfo.id,this.subGoodsInfo.id,"");
    }
  }
};
</script>

<style lang="less" scoped>
  @import "../status/css/jk.less";
  .jk-content{
    //特殊按钮标记
    .goods_id_2_tjnr{
      position: absolute;
      height: 1.2rem;
      width: 4rem;
      z-index: 999;
      left: 5.5rem;
      top:6rem;
    }
    .goods_id_2_xcap{
      position: absolute;
      height: 1.2rem;
      width: 4rem;
      z-index: 999;
      left: 0.5rem;
      top:6rem;
    }

    .goods_id_13_a{
      position: absolute;
      height: 0.7rem;
      width: 2.2rem;
      z-index: 999;
      left: 6.8rem;
      top:6.3rem;
    }
    .goods_id_13_b{
      position: absolute;
      height: 0.7rem;
      width: 2.2rem;
      z-index: 999;
      left: 6.8rem;
      top: 9.4rem;
    }
    .goods_id_13_c{
      position: absolute;
      height: 0.7rem;
      width: 2.2rem;
      z-index: 999;
      left: 6.8rem;
      top:12.5rem;
    }
    .goods_id_27{
      position: absolute;
      height: 1.1rem;
      width: 3.3rem;
      z-index: 999;
      left: 6rem;
      top:3.2rem;

    }
    .goods_id_28{
      position: absolute;
      height: 1.1rem;
      width: 3.3rem;
      z-index: 999;
      left: 6rem;
      top:3.2rem;
    }
  }

  .rssn_1{
    position: absolute;
    height: 1.1rem;
    width: 2.5rem;
    z-index: 999;
    left: 6rem;
    top:1.8rem;
  }
  .rssn_2{
    position: absolute;
    height: 1.1rem;
    width: 2.5rem;
    z-index: 999;
    left: 6rem;
    top:3.2rem;
  }
  .rssn_3{
    position: absolute;
    height: 1.1rem;
    width: 2.5rem;
    z-index: 999;
    left: 6rem;
    top:4.5rem;
  }
  .rssn_4{
    position: absolute;
    height: 1.1rem;
    width: 2.5rem;
    z-index: 999;
    left: 6rem;
    top:5.8rem;
  }
  .rssn_5{
    position: absolute;
    height: 1.1rem;
    width: 2.5rem;
    z-index: 999;
    left: 6rem;
    top:7.2rem;
  }
  .rssn_6{
    position: absolute;
    height: 1.1rem;
    width: 2.5rem;
    z-index: 999;
    left: 6rem;
    top:8.6rem;
  }
  .rssn_7{
    position: absolute;
    height: 1.1rem;
    width: 2.5rem;
    z-index: 999;
    left: 6rem;
    top:10rem;
  }
  .rssn_8{
    position: absolute;
    height: 1.1rem;
    width: 2.5rem;
    z-index: 999;
    left: 6rem;
    top:11.3rem;
  }
  .rssn_9{
    position: absolute;
    height: 1.1rem;
    width: 2.5rem;
    z-index: 999;
    left: 6rem;
    top:12.6rem;
  }
  .xcfw_1{
    position: absolute;
    height: 1.0rem;
    width: 2.0rem;
    z-index: 999;
    left: 7.2rem;
    top:8.5rem;
  }
  .xcfw_2{
    position: absolute;
    height: 1.0rem;
    width: 2.0rem;
    z-index: 999;
    left: 7.2rem;
    top:9.9rem;
  }


  .shuangliu_1{
    position: absolute;
    height: 1.0rem;
    width: 2.0rem;
    z-index: 999;
    left: 7.2rem;
    top:7.8rem;
  }

  .shuangliu_2{
    position: absolute;
    height: 1.0rem;
    width: 2.0rem;
    z-index: 999;
    left: 7.2rem;
    top:9.1rem;
  }
  .shuangliu_3{
    position: absolute;
    height: 1.0rem;
    width: 2.0rem;
    z-index: 999;
    left: 7.2rem;
    top:10.4rem;
  }

  .qjf_1{
    position: absolute;
    height: 1rem;
    width: 2.0rem;
    z-index: 999;
    left: 7.2rem;
    top:6.8rem;
  }
  .qjf_2{
    position: absolute;
    height: 1.0rem;
    width: 2.0rem;
    z-index: 999;
    left: 7.2rem;
    top:10rem;
  }

  .ymq_1{
    position: absolute;
    height: 1.0rem;
    width: 2.0rem;
    z-index: 999;
    left: 7.2rem;
    top:10.3rem;
  }

  .ppq_1{
    position: absolute;
    height: 1.0rem;
    width: 2.0rem;
    z-index: 999;
    left: 7.2rem;
    top:11.4rem;
  }




  .tianfu_1{
    position: absolute;
    height: 1.0rem;
    width: 2.0rem;
    z-index: 999;
    left: 7.2rem;
    top:7.8rem;
  }

  .tianfu_2{
    position: absolute;
    height: 1.0rem;
    width: 2.0rem;
    z-index: 999;
    left: 7.2rem;
    top:9.1rem;
  }
  .tianfu_3{
    position: absolute;
    height: 1.0rem;
    width: 2.0rem;
    z-index: 999;
    left: 7.2rem;
    top:10.4rem;
  }

  .dialog-wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.45);
    z-index: 100;
    ._content {
      width: 100%;
      position: absolute;
      top: 1rem;
      left: 0;
      ._window {
        margin: 0 0.7rem;
        padding: 0.8rem 0.8rem 0.4rem;
        background: url("../status/img/dialog_bg.png") repeat-x left center;
        background-size: auto 100%;
        border: 3px solid #CCAD8A;
        border-radius: 0.2rem;
        min-height: 20vh;
        max-height: 75vh;
        overflow-y: scroll;
        img {
          max-width: 100%;
        }

        ._title {
          margin-bottom: 1rem;
        }
        ._status {
          text-align: center;
          margin-top: -0.8rem;
          img {
            width: 1.8rem;
          }
        }
        ._tips {
          width: 110%;
          margin-left: -5%;
          padding: 0.1rem 0 0.5rem;
          font-size: 0.36rem;
          color: #c8b489;
          text-align: center;
        }
        ._list {
          ._xiangmu {
            color: #c8b489;
            text-align: center;
            border: 2px solid #CCAD8A;
            border-radius: 0.2rem;
            padding-bottom: 0.2rem;
            ._h1 {
              font-size: 0.54rem;
              padding: 0.15rem 0;
            }
            ._h2 {
              font-size: 0.4rem;
              padding: 0.2rem 0;
              background: #CCAD8A;
              border-radius: 0.1rem;
              color: #a71b1e;
              margin: 0 0.2rem;
            }
          }
          ._dijian {
            color: #c8b489;
            text-align: center;
            border: 2px solid #CCAD8A;
            border-radius: 0.2rem;
            padding: 0.2rem;
            line-height: 0.8rem;
            margin-top: 0.3rem;
            font-size: 0.36rem;
            span._hd {
              display: block;
              background: #CCAD8A;
              border-radius: 0.1rem;
              color: #a71b1e;
              font-weight: 600;
              font-size: 0.48rem;
            }
          }
        }
        ._btn {
          text-align: center;
          padding: 0.7rem 0 0;
          img {
            max-width: 110%;
            margin-left: -5%;
          }
        }

        &._window_w {
          padding: 0.2rem;
          ._title {
            padding-top: 0.3rem;
            margin-bottom: 0;
          }
        }
      }
      ._closed {
        padding: 0.4rem 0;
        text-align: center;
        img {
          width: 1.2rem;
        }
      }
    }
  }
  .input_account{
      //background: none;
      border: 0;
    //background-color: #CCAD8A;
      width: 94%;
      padding: 0 3%;
      font-size: 0.48rem;
      font-weight: 800;
    border-radius: 0.1rem;
      color: #a71b1e;
    }
    .input_account::placeholder {
      font-size: 0.44rem;
      font-weight: 100;
      color: #a71b1e;
      opacity: 0.7;
  }
</style>
